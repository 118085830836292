<template>
  <div class="proyect">
    <Header
      nameProject="Vera"
      subtitle="Aplicación de alimentación saludable"
      img="img/veraapp.png"
      color="#B2DFDB"
    />

    <Section
      intro="El proyecto Vera consiste en una aplicación de alimentación saludable, para ayudar a los usuarios a que sean más conscientes de lo que consumen y así puedan mejorar su alimentación y su salud."
      problem="El problema a resolver en este proyecto era crear una plataforma que sirviera como herramienta para ayudar a los consumidores a ser más conscientes. Se buscaba poner conciencia y enseñar a las personas para que fueran ellos mismos quienes pudieran tener unas nociones básicas sobre qué es lo que están consumiendo y si esos alimentos les pueden afectar tanto positivamente como negativamente."
      description="El objetivo de este proyecto era crear una plataforma que sirviera como herramienta para ayudar, de alguna forma, a la sociedad. En este caso, a través de la alimentación, ya que, en definitiva, somos lo que comemos. Gracias a los etiquetados de algunos productos podemos ser un poco más conscientes.

Pero, en ocasiones, la tarea de descifrar los etiquetados puede resultar complicada y resulta difícil tomar la decisión más saludable. Por otro lado, no siempre es posible distinguir qué ingredientes son beneficiosos o no y para ello es necesario tener conocimientos de nutrición o dietética. 

La plataforma seguirá la filosofía Realfooding que fomenta la alimentación de comida real y buenos procesados, evitando los ultraprocesados. 
"
      fecha="Enero 2019 - Junio 2019"
      rol="Product Design & Strategy"
      servicios="Research · UX Design · UI Design "
      img="img/veraa.png"
      title="Problema"
    />
    <div class="casestudy container">
      <img
        class="caseImg"
        src="img/food_mobile.jpg"
        alt="Móvil fotografiando un plato de comida" 
      />
      <section class="sectionText" >
        <div class="titlesection">
          <h2>Empatía</h2>
        </div>
        <div class="research text">
          <h3>Investicación</h3>
          <p>
            Para poder conocer con más detalle y con más criterio el problema se
            desarrolló una investigación cuantitativa y cualitativa. Con la
            investigación a través de las encuestas y las entrevistas se pudo
            conocer en primera mano las opiniones sobre la temática de la
            plataforma a los futuros usuarios de la plataforma.
          </p>
          <ul class="list">
            <h3>NECESIDADES DEL USUARIO</h3>

            <li>
              Reducir el tiempo invertido en identificar productos saludables.
            </li>
            <li>
              Simplificar la forma de identificar los valores nutricionales.
            </li>
            <li>Facilitar la información de los alimentos que se consumen.</li>
            <li>
              Personalizar el registro de alimentos que necesita cada individuo
            </li>
            <li>Comparar varios alimentos.</li>
            <li>Más información de los alimentos que se consumen</li>
            <li>
              Sencillo para las personas que no tiene un nivel alto de
              conocimiento de nutrición.
            </li>
          </ul>
        </div>
      </section>

      <img
        class="caseImg"
        src="img/app.jpg"
        alt="Móvil fotografiando un plato de comida" 
      />

      <section class="sectionText" >
        <div class="titlesection">
          <h2>Usuarios</h2>
        </div>
        <div class="testing text">
          <h3>Testing</h3>
          <p>
            Se realizaron test de usuarios con futuros usuarios, se analizaron
            los resultados obtenidos y se dieron soluciones a los diferentes
            problemas.
          </p>
          <p>
            Algunas de las mejoras que se plantearon tras los test son las
            siguientes: mejora del onboarding de inicio, cambio del icono del
            botón de escáner, mejora el feedback, Agilizar la comparación
            incluyendo la opción mirar en el historial o favoritos.
          </p>
        </div>
      </section>
      <img
        class="caseImg"
        src="img/food.jpg"
        alt="Cuenco con avena y frutos secos" 
      />

      <section class="sectionText" >
        <div class="titlesection">
          <h2>La propuesta</h2>
          <p>
            App de reconocimiento de imagen e identificación de alimentos
            saludable
          </p>
        </div>
        <div class="testing text">
          <h3>Solución</h3>
          <p>
            La idea final desarrollada es una aplicación móvil que escanea
            alimentos. Con este escáner se identifica el alimento en cuestión y
            devuelve al usuario una tarjeta con su descripción e información
            detallada del alimento.
          </p>
          <p class="text">
            El escáner se realiza a través del reconocimiento de imagen, aunque
            siempre tendrá opción de realizar la búsqueda a través de un
            buscador por texto o por audio para mejorar la accesibilidad.
          </p>
        </div>
      </section>

      <div class="solution">
        <img
          class="caseImg"
          src="img/Vera_home.jpg"
          alt="Pantalla home del proyecto Vera" 
        />

        <p class="text">
          En cuanto a las tarjetas de los alimentos, la información
          indispensable que aparecerá será el listado de ingredientes, marcando
          los diferentes ingredientes como los aditivos o ingredientes insanos.
          Además, llevará la escala de alimentos de la filosofía realfooding, es
          decir, indicar si son comida real, buen procesado o ultraprocesado.
        </p>
        <img
          class="caseImg"
          src="img/Vera_info.jpg"
          alt="Pantallas de información de alimentos del proyecto Vera"
        />

        <p class="text">
          Y, finalmente, indicar los aditivos y los valores nutricionales en un
          semáforo de color. Además, en los casos en los que el alimento sea
          ultraporcesado o buen procesado aparecerá una sugerencia de alimentos
          más saludables para poder fomentar y enseñar a los usuarios a
          encontrar soluciones más saludables.
        </p>

        <img
          class="caseImg"
          src="img/Vera_List_and_search.jpg"
          alt="Pantallas de Lista y buscador de alimentos del proyecto Vera"
        />
        <p class="text">
          Por otro lado, también se incluye la funcionalidad de crear listas
          para así poder tener una gestión de los alimentos que se compra, e
          incluso compartir y seguir otras listas para crear una comunidad donde
          poder encontrar toda la información que necesiten y conseguir que los
          usuarios sean más conscientes de los alimentos que consumen y así
          puedan mejorar su alimentación y su salud.
        </p>
        <img
          class="caseImg"
          src="img/Vera_profile.jpg"
          alt="Pantallas de perfil del proyecto Vera"
        />
        <img
          class="caseImg"
          src="img/Vera_all.jpg"
          alt="Todas las pantallas del proyecto Vera"
        />
      </div>
      <div class="video">
        <iframe
          src="https://www.youtube.com/embed/6zbsZZNq6jk"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="">
        <ul class="pagination">
          <li v-for="item in object" :key="item" class="project">
            <router-link :to="{ path: item.link }" class="link"
              >{{ item.msgButton }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Section from "../components/Section.vue";

export default {
  name: "Vera",
  components: {
    Header,
    Section,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      object: [
        {
          link: "/Mimo",
          img: "img/vera.png",
          msgButton: "← Mimo",
        },
        {
          link: "/Mibus",
          img: "img/aula.png",
          msgButton: "Mibus →",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.casestudy {
  max-width: 1224px;
  margin: 2rem auto;

  .pagination {
    margin: 3rem auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0;

    li {
      display: flex;
      text-decoration: none;
      align-items: center;

      a {
        font-size: 2.5rem;
        font-family: "DM Serif Display", sans-serif;
      }
    }
  }
}

.caseImg {
  height: auto;
}

.list {
  padding: 0;
  ::marker {
    color: #404b69;
  }
  li {
    list-style: none;
    margin: 1rem 0;
    list-style-type: disc;
  }
}
.destacado {
  background: #dbedf3;
  display: flex;
  justify-content: center;
  padding: 8rem;
  border-radius: 1rem;
}

.solution {
  max-width: 1224px;

  .text {
    margin: 6rem auto;
    max-width: 44rem;
  }
  img {
    width: 100%;
  }
}

iframe {
  max-width: 1224px;
  width: 100%;
  max-height: 600px;
  height: 50vw;
}

.video {
  margin: 4rem 0;
  height: auto
}
</style>
