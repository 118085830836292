<template>
  <div class="project">

    <section class="sectionText information container" data-aos="fade-up" data-aos-duration="1000">
      <div class="data">
        <div class="itemdata">
          <h3>Fecha</h3>
          <p>{{ fecha }}</p>
        </div>

        <div class="itemdata">
          <h3>Servicios</h3>
          <p>{{ servicios }}</p>
        </div>

        <div class="itemdata">
          <h3>Rol</h3>
          <p>{{ rol }}</p>
        </div>
      </div>

      <div class="itemInfo">
        <h4>{{ intro }}</h4>
      </div>
    </section>

    <section class="process" >
      <div class="elements">
        <h3>EL proceso →</h3>
        <div class="list">
          <div class="item">
            <span class="material-icons"> favorite </span>
            <h4>Empatía</h4>
            <p>Aprender de los usuarios para los que diseñas</p>
          </div>
          <div class="item">
            <span class="material-icons"> create</span>
            <h4>Definición</h4>
            <p>
              Establecer el punto de partida basados en las necesidades de los
              usuarios e indicios
            </p>
          </div>
          <div class="item">
            <span class="material-icons"> emoji_objects</span>
            <h4>Ideación</h4>
            <p>Genarar ideas y soluciones del problema</p>
          </div>
          <div class="item">
            <span class="material-icons"> phonelink</span>
            <h4>Prototipado</h4>
            <p>Contruir y hacer tangible la idea para mostrarsela al usuario</p>
          </div>
          <div class="item">
            <span class="material-icons"> 3p</span>
            <h4>Testeo</h4>
            <p>Testear con el usuario y apreder de su feedback</p>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionText container" >
      <div class="titlesection">
        <h2>Contexto</h2>
      </div>
      <div class="text">
        <h3>Descripción</h3>
        <p>{{ description }}</p>

        <h3>{{ title }}</h3>
        <p>{{ problem }}</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Section",

  props: {
    intro: String,
    problem: String,
    description: String,
    rol: String,
    fecha: String,
    servicios: String,
    img: String,
    title: String,
  },
};
</script>


<style lang="scss" scoped>
.information {
  max-width: 1224px;
  display: flex;
  justify-content: space-between;
  margin: 6rem auto;
  .itemInfo {
    max-width: 44rem;
  }
  .data {
    max-width: 24.5rem;
    min-width: 18rem;
    width: 100%;
    .itemdata {
      margin: 0 1.5rem 3rem 0;
    }
  }
}
.context {
  max-width: 1224px;
  margin: auto;
}

h3 {
  margin: 0 0 1rem 0;
}
.process {
  background: #f0f7fa;
  margin: 6rem auto;
  padding: 4rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .elements {
    padding: 0 1.5rem;
    max-width: 1224px;
  }
  h3 {
    max-width: 1224px;
    margin: 0 auto 3rem auto;
  }

  .list {
    display: flex;
    margin: 2rem auto;
    width: auto;

    .item {
      display: flex;
      flex-direction: column;
      position: static;
      min-width: 220px;
      width: 220px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        border-radius: 1rem;
        margin: 0 0 0.5rem 0;
        background: rgba(255, 255, 255, 0.8);
        color: #404b69;
      }
      p {
        margin: 0;
        white-space: normal;
      }
      h4 {
        margin: 1rem 0 0.5rem 0;
      }
    }

    .item:not(:last-child) {
      margin: 0 1.5rem 2rem 0;
    }
  }
}

img {
  max-width: 1224px;
  margin: auto;
}
</style>
