<template>
  <header class="header">
    <div class="text">
      <h1>{{ nameProject }}</h1>
      <h2>{{ subtitle }}</h2>
    </div>
    <div class="imagenTop" data-aos="fade-up" data-aos-duration="1000">
      <img :src="img" :alt="nameProject" />
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",

  props: {
    nameProject: String,
    subtitle: String,
    img: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  //height: 800px;
  background: #dbedf3;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 10rem 0;

  .text {
    width: 100%;
    margin: 8rem 0 0 0;

    h1 {
      font-size: 3rem;
      font-weight: 600;
      font-style: normal;
      text-align: center;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    margin: 0;
    text-align: center;
  }

  .imagenTop {
    //background-color: salmon;
    position: relative;
    bottom: 0;
    top: 5rem;
    left: 0;
    right: 0;
    max-width: 1224px;
    height: auto;
    z-index: 4;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
